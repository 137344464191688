<template>
  <div>
    <h3 class="title">
      <i class="el-icon-location-information font-xl text-v"></i>
      <span class="text-v ml-1x">飞房实时监控数据</span>
    </h3>
    <!-- 地图 -->
    <div class="chart" ref="chartMap"></div>

    <!-- 横向表格 -->
    <dv-border-box-13 class="relative">
      <div class="table-small relative">
        <div style="width: 100%; height: 100%">
          <el-row class="row header">
            <el-col :span="5" class="flex-center">名称</el-col>
            <el-col :span="4" class="flex-center">今日</el-col>
            <el-col :span="5" class="flex-center bold">月累计</el-col>
            <el-col :span="5" class="flex-center">年累计</el-col>
            <el-col :span="5" class="flex-center">操作</el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="5" class="flex-center">≥2小时 疑似飞房</el-col>
            <el-col :span="4" class="flex-center">{{
              tableData.todayFfCnt || 0
            }}</el-col>
            <el-col :span="5" class="flex-center bold">{{
              tableData.monthFfCnt || 0
            }}</el-col>
            <el-col :span="5" class="flex-center">{{
              tableData.yearFfCnt || 0
            }}</el-col>
            <el-col :span="5" class="flex-center">
              <el-button
                type="primary"
                plain
                size="mini"
                @click="totalVisible = true"
                >查看详情</el-button
              >
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="5" class="flex-center">≥2小时 RCU离线</el-col>
            <el-col :span="4" class="flex-center">{{
              tableData.todayOffs || 0
            }}</el-col>
            <el-col :span="5" class="flex-center bold">{{
              tableData.monthOffs || 0
            }}</el-col>
            <el-col :span="5" class="flex-center">{{
              tableData.yearOffs || 0
            }}</el-col>
            <el-col :span="5" class="flex-center">
              <el-button
                type="primary"
                plain
                size="mini"
                @click="rcuVisible = true"
                >查看详情</el-button
              ></el-col
            >
          </el-row>
        </div>
      </div>
    </dv-border-box-13>

    <!-- 疑似飞房详情 -->
    <flying-room-map-total
      ref="flyingRoomMapTotal"
      :visible.sync="totalVisible"
      :hotel-list="hotelList"
      :brand-id="brandId"
      @detail="lookHotelDetail"
    ></flying-room-map-total>
    <!-- RCU离线详情 -->
    <flying-room-map-rcu
      ref="flyingRoomMapRcu"
      :brand-id="brandId"
      :visible.sync="rcuVisible"
      :hotel-list="hotelList"
    ></flying-room-map-rcu>
  </div>
</template>

<script>
// 引入中国地图
import "echarts/map/js/china.js";
import {
  biFeiFangCollectStatistics,
  biFeiFangMapStatistics,
} from "../../../../api";
// 引入 ECharts 主模块
var echarts = require("echarts/lib/echarts");
// 引入提示框和标题组件
require("echarts/lib/component/tooltip");
require("echarts/lib/component/title");

import FlyingRoomMapTotal from "@/components/page/mdInfo/components/FlyingRoomMapTotal";
import FlyingRoomMapRcu from "@/components/page/mdInfo/components/FlyingRoomMapRcu";
export default {
  name: "FlyingRoomMap",

  props: {
    hotelList: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  components: { FlyingRoomMapTotal, FlyingRoomMapRcu },

  data() {
    return {
      brandId: "",
      chartMap: null,
      timer: null,
      tableData: {},

      totalVisible: false,
      rcuVisible: false,
    };
  },

  created() {},

  mounted() {
    this.chartMap = echarts.init(this.$refs.chartMap);
  },
  beforeDestroy() {
    this.clearTimeout();
  },

  methods: {
    resize() {
      this.chartMap.resize();
      this.$refs.flyingRoomMapTotal.resize();
      this.$refs.flyingRoomMapRcu.resize();
    },
    clearTimeout() {
      clearTimeout(this.timer);
      this.timer = null;
    },
    loadData(brandId) {
      this.brandId = brandId;
      this.clearTimeout();
      this.loadMapData(brandId);
      this.loadTotalData(brandId);
    },
    // 加载数据
    loadMapData(brandId) {
      biFeiFangMapStatistics({ brandId, t: new Date().getTime() })
        .then(({ data }) => {
          this.initMap(data.data);
          this.timer = setTimeout(() => {
            this.loadData(brandId);
          }, 10000);
        })
        .catch(() => {});
    },
    // 初始化地图
    initMap(data) {
      const numbers = []; // 疑似数组
      const arr = data.map((item) => {
        if (item.ffCnt > 0) {
          numbers.push(item.ffCnt);
        }
        return {
          name: item.city, // 数据项名称，在这里指地区名称
          value: [
            item.location.lng, // 地理坐标，经度 0
            item.location.lat, // 地理坐标，纬度 1
            item.ffCnt, // 疑似 2
            item.offs, // 离线 3
          ],
          // 提示工具
          tooltip: {
            show: true,
            trigger: "item", //数据项图形触发
            backgroundColor: "rgba(35,37,42,0.6)", //提示框浮层的背景颜色。
            textStyle: { color: "#fff" },
            padding: 8,
            formatter,
          },
          // 散点样式
          itemStyle: {
            color: item.ffCnt > 0 ? "#ff3b00" : "#979797",
          },
          rippleEffect: {
            //涟漪特效相关配置
            brushType: "stroke", //波纹的绘制方式，可选 'stroke' 和 'fill'
            scale: item.ffCnt > 0 ? 3 : 0,
          },
          label: {
            // show: true, //显示城市标签
            position: "right",
            formatter: "{b}",
            color: item.ffCnt > 0 ? "#ff3b00" : "#979797",
          },
        };
      });
      let max = Math.max(...numbers);
      let min = Math.min(...numbers);
      this.chartMap.setOption({
        geo: {
          map: "china",
          roam: false, //是否开启鼠标缩放和平移漫游
          aspectScale: 0.75,
          zoom: 1.2, //地图缩放比例,默认为1

          label: {
            show: false, //显示省份标签
          },

          //地图区域的多边形 图形样式
          itemStyle: {
            areaColor: "#1c8cd5", //区域颜色
            borderWidth: 1, //区域边框宽度
            borderColor: "#ccc", //区域边框颜色
            emphasis: {
              //是图形在高亮状态下的样式,比如在鼠标悬浮或者图例联动高亮时
              borderWidth: 1,
              areaColor: "#57bff7",
              borderColor: "#fff",
            },
          },
        },
        tooltip: {
          //这里设置提示框
          show: true,
          trigger: "item", //数据项图形触发
          backgroundColor: "#fff", //提示框浮层的背景颜色。
        },
        series: [
          {
            name: "",
            map: "china",
            type: "effectScatter", // series图表类型
            coordinateSystem: "geo", // series坐标系类型
            //设置散点大小
            symbolSize: function (val) {
              let size = 10;
              if (val[2] > 0) {
                if (max > min && max - min > 1) {
                  let center = (max - min) / 2 + min;
                  if (val[2] > center) {
                    size = 14;
                  }
                }
              }
              return size;
            },
            // symbolSize: 10,
            data: arr,
          },
        ],
      });

      function formatter(params) {
        return `
                      <ul>
                        <li class="bold">${params.name}飞房实时监控数据</li>
                        <li class="flex-start mt-1x">
                          <div
                            class="mr-1x tool-tip suspected"
                          ></div>
                          <p>疑似：${params.value[2]}</p>
                        </li>
                        <li class="flex-start mt-1x">
                          <div
                            class="mr-1x off-line tool-tip"
                          ></div>
                          <p>离线：${params.value[3]}</p>
                        </li>
                      </ul>
                    `;
      }
    },
    // 加载统计数据
    loadTotalData(brandId) {
      biFeiFangCollectStatistics({ brandId, t: new Date().getTime() })
        .then(({ data }) => {
          this.tableData = data.data;
        })
        .catch(() => {});
    },

    // 查看单个酒店飞房详情
    lookHotelDetail(row, allDate) {
      this.$emit("detail", row, allDate);
    },
  },
};
</script>
<style scoped lang="scss">
// 疑似
/deep/.suspected {
  background: #ff3b00;
}
// 离线
/deep/.off-line {
  background: #979797;
}

/deep/.tool-tip {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.table-small {
  padding: 16px 10px 12px 10px;
}

.row {
  &.header {
    background: rgba(7, 56, 236, 0.25);
  }
  height: 33%;
  .flex-center {
    height: 100%;
  }
  &:nth-child(3) {
    background: rgba(255, 255, 255, 0.15);
  }
}
</style>
