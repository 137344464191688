// 飞房看板 sdf
<template>
  <dv-full-screen-container class="bg">
    <!-- 头部 -->
    <header class="top flex-start">
      <el-row class="flex-grow">
        <el-col :span="8" class="flex-start">
          <el-image
            style="width: 24px; height: 24px; border-radius: 4px"
            :src="brandItem.logo"
            fit="fill"
          ></el-image>
          <h3 class="ml-1x" v-if="brandList.length < 2">
            {{ brandItem.brandName }}
          </h3>
          <el-dropdown v-else @command="handleCommand">
            <h3 class="ml-1x brand-name pointer">
              <span>{{ brandItem.brandName }}</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </h3>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(item, index) in brandList"
                :key="item.id"
                :command="index"
              >
                <div class="pv-1x">
                  <el-image
                    class="text-v"
                    style="width: 24px; height: 24px; border-radius: 4px"
                    :src="item.logo"
                    fit="fill"
                  ></el-image>
                  <span class="text-v ml-1x">{{ item.brandName }}</span>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <p class="ml-3x">{{ dateTime }}</p>
          <p class="ml-1x">{{ week }}</p>
        </el-col>
        <el-col :span="8">
          <h2 class="text-c">智慧酒店飞房监控平台</h2>
        </el-col>
        <el-col :span="8" class="flex-end">
          <div @click="flyingRoomSearch">
            <el-input
              class="search-input"
              placeholder="飞房明细查询"
              size="small"
              suffix-icon="el-icon-search"
            ></el-input>
          </div>
        </el-col>
      </el-row>
    </header>

    <!-- 内容部分 -->
    <section class="box">
      <el-row>
        <!-- 左 -->
        <el-col :span="7">
          <!-- 飞房控制效果监控 -->
          <div class="left-data">
            <flying-room-line
              ref="flyingRoomLine"
              :brand-id="brandItem.id"
            ></flying-room-line>

            <flying-room-hotel
              ref="flyingRoomHoltel"
              :hotel-list="hotelList"
              @detail="lookHotelDetail"
            ></flying-room-hotel>
          </div>
        </el-col>
        <!-- 中 -->
        <el-col :span="10">
          <flying-room-map
            class="center-data"
            ref="flyingRoomMap"
            :hotel-list="hotelList"
            @detail="lookHotelDetail"
          ></flying-room-map>
        </el-col>
        <!-- 右 -->
        <el-col :span="7">
          <div class="right-data">
            <flying-room-bar ref="flyingRoomBar"></flying-room-bar>

            <flying-room-region ref="flyingRoomRegion"></flying-room-region>
          </div>
        </el-col>
      </el-row>
    </section>

    <!-- 飞房查询 -->
    <flying-room-search
      ref="flyingRoomSearch"
      :hotel-list="hotelList"
      :brand-id="brandItem.id"
    ></flying-room-search>
  </dv-full-screen-container>
</template>

<script>
import FlyingRoomLine from "@/components/page/mdInfo/components/FlyingRoomLine";
import FlyingRoomHotel from "@/components/page/mdInfo/components/FlyingRoomHotel";
import FlyingRoomMap from "@/components/page/mdInfo/components/FlyingRoomMap";
import FlyingRoomBar from "@/components/page/mdInfo/components/FlyingRoomBar";
import FlyingRoomRegion from "@/components/page/mdInfo/components/FlyingRoomRegion";
import FlyingRoomSearch from "../components/FlyingRoomSearch.vue";
import moment from "moment";
import { getBrandSelect, getHotelSelect } from "@/api";

export default {
  name: "FlyingRoom",

  components: {
    FlyingRoomLine,
    FlyingRoomHotel,
    FlyingRoomMap,
    FlyingRoomBar,
    FlyingRoomRegion,
    FlyingRoomSearch,
  },

  data() {
    return {
      visible: false,
      dateTime: "", // 时间
      week: "", // 星期
      timer: null,

      brandItem: { id: "" },
      brandList: [],
      hotelList: [],
    };
  },

  computed: {
    // 用户信息
    user() {
      return this.$store.state.user;
    },
    // 平台名称
    platformName() {
      return this.user.brandName;
    },
    // 平台logo
    platformLogo() {
      return this.user.logo;
    },
  },

  created() {
    this.getTime();
    this.setInterval();
    this.loadBrand();
  },

  mounted() {
    window.addEventListener("resize", this.windowResize);
  },

  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
    window.removeEventListener("resize", this.windowResize);
  },

  methods: {
    // 窗口尺寸变化
    windowResize() {
      this.$refs.flyingRoomLine.resize();
      this.$refs.flyingRoomBar.resize();
      this.$refs.flyingRoomMap.resize();
      this.$refs.flyingRoomHoltel.resize();
      this.$refs.flyingRoomRegion.resize();
      this.$refs.flyingRoomSearch.resize();
    },
    // 获取动态时间
    getTime() {
      const now = moment();
      this.dateTime = now.format("YYYY-MM-DD HH:mm:ss");
      const weeks = {
        0: "星期天",
        1: "星期一",
        2: "星期二",
        3: "星期三",
        4: "星期四",
        5: "星期五",
        6: "星期六",
      };
      this.week = weeks[now.day()];
    },
    // 设置定时器
    setInterval() {
      this.timer = setInterval(() => {
        this.getTime();
      }, 1000);
    },

    // 加载品牌
    loadBrand() {
      getBrandSelect()
        .then(({ data }) => {
          this.brandList = data.data;
          this.brandItem = this.brandList[0];
          this.loadHoltel(this.brandItem.id);
          this.loadChart();
        })
        .catch(() => {});
    },

    // 加载图表数据
    loadChart() {
      this.$refs.flyingRoomLine.loadData(this.brandItem.id);
      this.$refs.flyingRoomHoltel.loadData(this.brandItem.id);
      this.$refs.flyingRoomBar.loadData(this.brandItem.id);
      this.$refs.flyingRoomRegion.loadData(this.brandItem.id);
      this.$refs.flyingRoomMap.loadData(this.brandItem.id);
    },
    // 加载品牌下的酒店列表
    loadHoltel(id) {
      getHotelSelect(id)
        .then(({ data }) => {
          this.hotelList = data.data;
        })
        .catch(() => {});
    },
    // 切换品牌
    handleCommand(index) {
      this.brandItem = this.brandList[index];
      this.loadHoltel(this.brandItem.id);
      this.loadChart(this.brandItem.id);
    },

    // 飞房明细查询
    flyingRoomSearch() {
      this.$refs.flyingRoomSearch.resetSearch();
    },
    // 酒店飞房查询
    lookHotelDetail(row, customDate) {
      this.$refs.flyingRoomSearch.loadHotelData(row.hid, customDate);
    },
  },
};
</script>
<style lang="scss">
@import "../../../../assets/css/common";
// 抽屉头部间距
.el-drawer__header {
  margin-bottom: 24px;
}
</style>

<style scoped lang="scss">
.bg {
  background: url("../../../../assets/img/mapg.jpg");
  background-size: 100% 100%;
  font-size: 14px;
  color: #fff;
  // 头部
  .top {
    padding: 2vh 2vh 0;
    line-height: 28px;
    .brand-name {
      color: #fff;
    }

    .pointer:hover {
      color: #57bff7;
    }
  }
  .search-input {
    width: 200px;
    /deep/.el-input__inner {
      background: transparent;
    }
  }
  // 内容
  .box {
    padding: 0 0.75vh;
    .left-data,
    .right-data {
      padding: 0 1vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-sizing: border-box;
    }
    .center-data {
      padding: 0 1vh;
    }

    /deep/ .title {
      margin: 3vh 0 1vh;
      font-size: 16px;
      font-weight: normal;
      line-height: 18px;
    }

    /deep/.mt-1 {
      margin-top: 1vh;
    }

    /deep/.mt-1-5 {
      margin-top: 1.5vh;
    }
    /deep/.mt-2 {
      margin-top: 2vh;
    }

    // 图表高度
    /deep/.chart {
      height: 67.5vh;
      box-sizing: border-box;
    }
    /deep/.chart-small {
      height: 40vh;
      box-sizing: border-box;
    }

    /deep/.table {
      height: 40vh;
      overflow: hidden;
      box-sizing: border-box;
    }
    /deep/.table-small {
      height: 19vh;
      box-sizing: border-box;
    }
  }
  /deep/.drawer {
    padding: 0 16px;
    color: #333;
  }

  /deep/ .el-button--primary.is-plain {
    background: transparent;
  }
}
</style>
