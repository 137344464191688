var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "dv-full-screen-container",
    { staticClass: "bg" },
    [
      _c(
        "header",
        { staticClass: "top flex-start" },
        [
          _c(
            "el-row",
            { staticClass: "flex-grow" },
            [
              _c(
                "el-col",
                { staticClass: "flex-start", attrs: { span: 8 } },
                [
                  _c("el-image", {
                    staticStyle: {
                      width: "24px",
                      height: "24px",
                      "border-radius": "4px",
                    },
                    attrs: { src: _vm.brandItem.logo, fit: "fill" },
                  }),
                  _vm.brandList.length < 2
                    ? _c("h3", { staticClass: "ml-1x" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.brandItem.brandName) +
                            "\n        "
                        ),
                      ])
                    : _c(
                        "el-dropdown",
                        { on: { command: _vm.handleCommand } },
                        [
                          _c(
                            "h3",
                            { staticClass: "ml-1x brand-name pointer" },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.brandItem.brandName)),
                              ]),
                              _c("i", {
                                staticClass:
                                  "el-icon-arrow-down el-icon--right",
                              }),
                            ]
                          ),
                          _c(
                            "el-dropdown-menu",
                            { attrs: { slot: "dropdown" }, slot: "dropdown" },
                            _vm._l(_vm.brandList, function (item, index) {
                              return _c(
                                "el-dropdown-item",
                                { key: item.id, attrs: { command: index } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "pv-1x" },
                                    [
                                      _c("el-image", {
                                        staticClass: "text-v",
                                        staticStyle: {
                                          width: "24px",
                                          height: "24px",
                                          "border-radius": "4px",
                                        },
                                        attrs: { src: item.logo, fit: "fill" },
                                      }),
                                      _c(
                                        "span",
                                        { staticClass: "text-v ml-1x" },
                                        [_vm._v(_vm._s(item.brandName))]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                  _c("p", { staticClass: "ml-3x" }, [
                    _vm._v(_vm._s(_vm.dateTime)),
                  ]),
                  _c("p", { staticClass: "ml-1x" }, [_vm._v(_vm._s(_vm.week))]),
                ],
                1
              ),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("h2", { staticClass: "text-c" }, [
                  _vm._v("智慧酒店飞房监控平台"),
                ]),
              ]),
              _c("el-col", { staticClass: "flex-end", attrs: { span: 8 } }, [
                _c(
                  "div",
                  { on: { click: _vm.flyingRoomSearch } },
                  [
                    _c("el-input", {
                      staticClass: "search-input",
                      attrs: {
                        placeholder: "飞房明细查询",
                        size: "small",
                        "suffix-icon": "el-icon-search",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "section",
        { staticClass: "box" },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 7 } }, [
                _c(
                  "div",
                  { staticClass: "left-data" },
                  [
                    _c("flying-room-line", {
                      ref: "flyingRoomLine",
                      attrs: { "brand-id": _vm.brandItem.id },
                    }),
                    _c("flying-room-hotel", {
                      ref: "flyingRoomHoltel",
                      attrs: { "hotel-list": _vm.hotelList },
                      on: { detail: _vm.lookHotelDetail },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c("flying-room-map", {
                    ref: "flyingRoomMap",
                    staticClass: "center-data",
                    attrs: { "hotel-list": _vm.hotelList },
                    on: { detail: _vm.lookHotelDetail },
                  }),
                ],
                1
              ),
              _c("el-col", { attrs: { span: 7 } }, [
                _c(
                  "div",
                  { staticClass: "right-data" },
                  [
                    _c("flying-room-bar", { ref: "flyingRoomBar" }),
                    _c("flying-room-region", { ref: "flyingRoomRegion" }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("flying-room-search", {
        ref: "flyingRoomSearch",
        attrs: { "hotel-list": _vm.hotelList, "brand-id": _vm.brandItem.id },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }