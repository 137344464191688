<template>
  <div>
    <div class="title flex-between">
      <p>
        <i class="el-icon-pie-chart font-xl text-v"></i>
        <span class="text-v ml-1x">当前异常地区分布</span>
      </p>

      <el-button type="primary" plain size="mini" style="opacity: 0"
        >查看详情</el-button
      >
    </div>
    <!-- 横向柱状图 -->
    <dv-border-box-12 class="mt-1 relative">
      <div class="chart-small" ref="chartBar"></div>
    </dv-border-box-12>
  </div>
</template>

<script>
// 引入 ECharts 主模块
var echarts = require("echarts/lib/echarts");
// 引入柱状图
require("echarts/lib/chart/line");
// 引入提示框和标题组件
require("echarts/lib/component/tooltip");
require("echarts/lib/component/title");
import { biFeiFangMapAreaStatistics } from "../../../../api";
export default {
  name: "FlyingRoomRegion",

  data() {
    return {
      chartBar: null,
      timer: null,
    };
  },

  created() {},

  beforeDestroy() {
    this.clearTimeout();
  },

  mounted() {
    this.chartBar = echarts.init(this.$refs.chartBar, "dark");
  },

  methods: {
    clearTimeout() {
      clearTimeout(this.timer);
      this.timer = null;
    },
    resize() {
      this.chartBar.resize();
    },
    loadData(brandId) {
      this.clearTimeout();
      biFeiFangMapAreaStatistics({ brandId, t: new Date().getTime() })
        .then(({ data }) => {
          this.timer = setTimeout(() => {
            this.loadData(brandId);
          }, 10000);
          const area = [];
          const suspected = [];
          const off = [];

          data.data.forEach((item) => {
            area.push(item.area);
            suspected.push(item.items.ffCnt);
            off.push(item.items.offs);
          });

          this.chartBar.setOption({
            backgroundColor: "",
            barGap: "30%",
            grid: {
              top: 50,
              left: 60,
              right: 40,
              bottom: 36,
            },
            legend: {
              top: 20,
              data: ["疑似", "离线"],
            },
            tooltip: {
              trigger: "axis",
            },
            xAxis: {
              type: "value",
              boundaryGap: [0, 0.01],
            },
            yAxis: {
              type: "category",
              data: area,
            },
            series: [
              {
                name: "疑似",
                type: "bar",
                stack: "one",
                data: suspected,
                // itemStyle: {
                //   color: "#f37570",
                // },
                // lineStyle: {
                //   width: 4,
                //   color: "#f37570",
                // },
              },
              {
                name: "离线",
                data: off,
                type: "bar",
                stack: "two",
                itemStyle: {
                  color: "#979797",
                },
                lineStyle: {
                  width: 4,
                  color: "#979797",
                },
              },
            ],
          });
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped></style>
