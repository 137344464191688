// 飞房查询
<template>
  <el-drawer
    title="飞房明细查询"
    :visible.sync="visible"
    direction="rtl"
    size="85%"
    :modal-append-to-body="false"
  >
    <div class="drawer">
      <header class="flex-start">
        <p class="flex-shrink">酒店名称：</p>
        <el-select
          class="mr-2x"
          v-model="search.hotelId"
          placeholder="请选择"
          clearable
          filterable
          @clear="searchData"
          @change="searchData"
        >
          <el-option
            v-for="item in hotelList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <p class="flex-shrink">房号：</p>
        <el-input
          class="mr-2x"
          placeholder="请输入房号"
          v-model="search.roomNo"
          clearable
          style="width: 200px"
          @change="searchData"
        ></el-input>
        <p class="flex-shrink">日期：</p>
        <el-date-picker
          style="width: 335px"
          v-model="date"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :clearable="true"
          value-format="yyyy-MM-dd HH:mm:ss"
          @change="dateChange"
        >
        </el-date-picker>
        <p class="flex-shrink ml-2x">疑似类型：</p>
        <el-select
          style="width: 180px"
          class="mr-2x"
          v-model="search.awt"
          placeholder="请选择"
          filterable
          clearable
          @clear="searchData"
          @change="searchData"
        >
          <el-option label="超时退房" :value="1"> </el-option>
          <el-option label="退房未拔卡" :value="3"> </el-option>
          <el-option label="插卡超时" :value="2"> </el-option>
        </el-select>

        <el-button type="primary" icon="el-icon-search" @click="searchData"
          >查询
        </el-button>
        <el-button icon="el-icon-refresh-left" @click="resetSearch"
          >重置
        </el-button>
      </header>

      <el-table
        class="mt-2x"
        :height="tableHeight"
        :data="tableData"
        :header-cell-style="{
          background: 'rgba(250, 250, 250, 1)',
        }"
      >
        <el-table-column
          prop="hname"
          label="酒店名称"
          min-width="120"
        ></el-table-column>
        <el-table-column prop="roomNo" label="房号" min-width="100">
        </el-table-column>
        <el-table-column prop="awt" label="疑似类型" min-width="120">
          <template slot-scope="{ row }">{{
            row.awt === 1
              ? "超时退房"
              : row.awt === 2
              ? "插卡超时"
              : "退房未拔卡"
          }}</template>
        </el-table-column>
        <el-table-column prop="sat" label="开始时间" min-width="120">
        </el-table-column>
        <el-table-column
          prop="ent"
          label="结束时间"
          min-width="120"
        ></el-table-column>
        <el-table-column prop="ffrar" label="持续时长" min-width="120">
          <template slot-scope="{ row }">{{ row | duration }}</template>
        </el-table-column>
        <el-table-column prop="descr" label="异常描述" min-width="150">
        </el-table-column>
      </el-table>

      <!--分页-->
      <footer class="p-2x flex-between">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="search.pageSize"
          :total="total"
          :current-page="current"
          @size-change="sizeChange"
          @current-change="currentChange"
        >
        </el-pagination>

        <el-button v-if="tableData.length" type="primary" @click="exportExl"
          >导出</el-button
        >
      </footer>
    </div>
  </el-drawer>
</template>

<script>
import { biFeiFangWarnPage } from "@/api";
import moment from "moment";
import { biFeiFangWarnPageExport } from "../../../../api";
export default {
  name: "FlyingRoomSearch",

  props: {
    brandId: {
      type: String,
      default: "",
    },
    hotelList: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      visible: false,

      date: [],

      search: {
        pageNum: 1,
        pageSize: 10,
        hotelId: "",
        start: "",
        end: "",
        awt: "", // 异常类型
        roomNo: "", // 房号
      },
      tableData: [],
      current: 1, // 当前页面
      total: 0, // 总数
      tableHeight: 0, // 表格高度
      timer: null,
    };
  },

  filters: {
    duration(row) {
      if (!row.ent) {
        return "持续中";
      } else {
        const start = moment(row.sat.replace(/-/gi, "-")).valueOf();
        const end = moment(row.ent.replace(/-/gi, "-")).valueOf();
        let difference = end - start;
        if (difference > 0) {
          function formatDuring(mss) {
            var days = parseInt(mss / (1000 * 60 * 60 * 24));
            var hours = parseInt(
              (mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            var minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = (mss % (1000 * 60)) / 1000;
            let time = "";
            if (days > 0) {
              time += `${days}天`;
            }
            if (hours > 0) {
              time += `${hours}小时`;
            }
            if (minutes > 0) {
              time += `${minutes}分`;
            }
            time += `${seconds}秒`;
            return time;
          }
          return formatDuring(difference);
        } else {
          return "";
        }
      }
    },
  },

  created() {
    this.resize();
  },

  methods: {
    // 导出表格
    exportExl() {
      if (!this.search.hotelId) {
        this.$message.error("请选择酒店名称后再导出");
        return;
      }
      let start = this.search.start;
      let end = this.search.end;
      let needTip = false; // 是否超过30天提示
      if (start && end) {
        let cha =
          moment(end.replace(/-/gi, "/")).valueOf() -
          moment(start.replace(/-/gi, "/")).valueOf();
        let day = cha / 1000 / 60 / 60 / 24;
        if (day > 30) {
          start = moment(end.replace(/-/gi, "/"))
            .subtract(30, "days")
            .format("YYYY-MM-DD hh:mm:ss");
          needTip = true;
        }
      } else {
        start = moment()
          .startOf("date")
          .subtract(29, "days")
          .format("YYYY-MM-DD HH:mm:ss");
        end = moment().format("YYYY-MM-DD HH:mm:ss");
        needTip = true;
      }

      biFeiFangWarnPageExport({
        brandId: this.brandId,
        hotelId: this.search.hotelId,
        roomNo: this.search.roomNo,
        awt: this.search.awt,
        start,
        end,
      })
        .then(({ data }) => {
          // 将二进制数据流转换为Blob对象
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          // 创建下载链接
          const url = window.URL.createObjectURL(blob);

          // 创建一个a标签，并模拟点击下载
          const a = document.createElement("a");
          a.href = url;
          a.download = `飞房明细查询.xlsx`;
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(a.href);
          document.body.removeChild(a);
          this.$message.success("已导出，请在下载列表中查看");
        })
        .catch(() => {});

      if (needTip) {
        this.$notify({
          title: "提示",
          message: "一次最多只能导出30天的数据",
          type: "warning",
        });
      }
    },
    resize() {
      let windowHeight = window.innerHeight;
      let fixedHeight = 195; // 页面固定占位高度
      this.tableHeight = windowHeight - fixedHeight;
    },
    resetDate() {
      let start = moment()
        .subtract(6, "days")
        .startOf("date")
        .format("YYYY-MM-DD HH:mm:ss");
      let end = moment().endOf("date").format("YYYY-MM-DD HH:mm:ss");
      this.date = [start, end];
      this.search.start = start;
      this.search.end = end;
    },
    // 重置
    reset() {
      this.search.pageNum = 1;
      this.search.pageSize = 10;
      this.search.hotelId = "";
      this.search.awt = "";
      this.search.roomNo = "";
      this.date = [];
      this.search.start = "";
      this.search.end = "";
    },
    // 重置查询
    resetSearch() {
      this.reset();
      this.searchData();
    },
    // 查询
    searchData() {
      this.search.pageNum = 1;
      this.loadData();
    },
    // 指定酒店
    loadHotelData(hotelId, customDate) {
      this.reset();
      if (hotelId) {
        this.search.hotelId = hotelId;
        if (!customDate) {
          this.date = [];
          this.search.start = "";
          this.search.end = "";
        } else {
          this.resetDate();
        }
      }
      this.loadData();
    },
    // 加载数据
    loadData() {
      this.visible = true;
      biFeiFangWarnPage({ ...this.search, brandId: this.brandId })
        .then(({ data }) => {
          this.total = data.data.total;
          this.tableData = data.data.rows || [];
        })
        .catch(() => {});
    },
    // 条数改变
    sizeChange(s) {
      this.search.pageNum = 1;
      this.search.pageSize = s;
      this.loadData();
    },
    // 页面改变
    currentChange(p) {
      this.search.pageNum = p;
      this.loadData();
    },

    // 日期改变
    dateChange(val) {
      if (val) {
        this.search.start = val[0];
        this.search.end = val[1];
      } else {
        this.search.start = "";
        this.search.end = "";
      }
      this.searchData();
    },
  },
};
</script>
<style scoped>
.empty {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}
</style>
