// 监控酒店
<template>
  <div>
    <div class="title flex-between">
      <p>
        <i class="el-icon-collection-tag font-xl text-v"></i>
        <span class="text-v ml-1x">重点监控酒店</span>
      </p>

      <div class="flex-end">
        <p class="mr-1x font-n" style="color: #ccc">七天累计</p>

        <el-button type="primary" plain size="mini" @click="lookDetail"
          >查看详情</el-button
        >
      </div>
    </div>

    <dv-border-box-12>
      <div class="table p-1x relative">
        <dv-scroll-board
          ref="scrollBoard"
          :config="config"
          style="width: 100%; height: 100%"
        />
        <p class="empty" v-if="config.data.length === 0">—— 暂无数据 ——</p>
      </div>
    </dv-border-box-12>

    <!-- 查看详情 -->
    <el-drawer
      title="七天飞房酒店排名前20"
      :visible.sync="visible"
      direction="rtl"
      size="80%"
      :modal-append-to-body="false"
    >
      <div class="drawer">
        <el-table
          class="mt-2x"
          :height="tableHeight"
          :data="tableData"
          :header-cell-style="{
            background: 'rgba(250, 250, 250, 1)',
          }"
        >
          <el-table-column prop="date" label="排名" min-width="60">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column prop="hname" label="酒店名称" min-width="130">
            <template slot-scope="{ row }">
              <el-button type="text" @click="lookHotelDetail(row)">{{
                row.hname
              }}</el-button>
            </template>
          </el-table-column>
          <el-table-column
            prop="rooms"
            label="房间数"
            min-width="100"
          ></el-table-column>
          <el-table-column prop="offs" label="离线超2小时次数" min-width="120">
          </el-table-column>
          <el-table-column prop="pms" label="PMS入住次数" min-width="100">
          </el-table-column>
          <el-table-column
            prop="ffCnt"
            label="疑似飞房数"
            min-width="100"
          ></el-table-column>
          <el-table-column prop="ffrate" label="飞房率" min-width="80">
            <template slot-scope="{ row }">{{ row.ffrate }} %</template>
          </el-table-column>
        </el-table>

        <footer class="flex-end pv-2x" v-if="tableData.length">
          <el-button type="primary" @click="exportExl">导出</el-button>
        </footer>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { biFeiFangTopPage } from "@/api";
import moment from "moment";
import { biFeiFangTopPageExport } from "../../../../api";
export default {
  name: "FlyingRoomHotel",

  props: {
    hotelList: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      visible: false,
      config: {
        header: ["序号", "酒店名称", "离线", "疑似"],
        data: [],
        headerBGC: "rgba(7, 56, 236, 0.25)",
        headerHeight: 40,
        columnWidth: [55, 208],
        rowNum: 8,
        evenRowBGC: "transparent",
        oddRowBGC: "rgba(255, 255, 255, 0.15)",
      },
      brandId: "", // 品牌id

      tableData: [],
      tableHeight: 0, // 表格高度
      timer: null,
    };
  },

  created() {
    this.resize();
  },

  beforeDestroy() {
    this.clearTimeout();
  },

  methods: {
    clearTimeout() {
      clearTimeout(this.timer);
      this.timer = null;
    },
    resize() {
      let windowHeight = window.innerHeight;
      let fixedHeight = 147; // 页面固定占位高度
      this.tableHeight = windowHeight - fixedHeight;
    },
    // 重置
    resetSearch() {
      this.search.page = 1;
      this.search.size = 10;
      this.search.hotelName = "";
      this.search.date = [
        moment().subtract(6, "days").format("YYYYMMDD"),
        moment().format("YYYYMMDD"),
      ];
      this.searchData();
    },
    // 查询数据
    searchData() {
      this.search.page = 1;
      this.loadDetail();
    },
    // 加载数据
    loadData(brandId) {
      this.clearTimeout();
      this.brandId = brandId;
      biFeiFangTopPage({
        brandId: this.brandId,
        pageSize: 8,
        t: new Date().getTime(),
      })
        .then(({ data }) => {
          const arr = data.data.map((item, i) => {
            return [i + 1, item.hname, item.offs, item.ffCnt];
          });

          this.config = {
            header: ["序号", "酒店名称", "离线", "疑似"],
            data: arr,
            headerBGC: "rgba(7, 56, 236, 0.25)",
            headerHeight: 40,
            columnWidth: [52, 208],
            rowNum: 8,
            evenRowBGC: "transparent",
            oddRowBGC: "rgba(255, 255, 255, 0.15)",
          };

          this.timer = setTimeout(() => {
            this.loadData(brandId);
          }, 10000);
        })
        .catch(() => {});
    },

    // 查看详情
    lookDetail() {
      this.visible = true;
      this.loadDetail();
    },
    // 加载详情
    loadDetail() {
      biFeiFangTopPage({
        brandId: this.brandId,
        pageSize: 20,
      })
        .then(({ data }) => {
          this.tableData = data.data;
        })
        .catch(() => {});
    },

    // 查看单个酒店飞房详情
    lookHotelDetail(row) {
      this.$emit("detail", row, true);
    },

    // 导出表格
    exportExl() {
      biFeiFangTopPageExport({
        brandId: this.brandId,
        pageSize: 20,
      })
        .then(({ data }) => {
          // 将二进制数据流转换为Blob对象
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          // 创建下载链接
          const url = window.URL.createObjectURL(blob);

          // 创建一个a标签，并模拟点击下载
          const a = document.createElement("a");
          a.href = url;
          a.download = `七天飞房酒店排名前20.xlsx`;
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(a.href);
          document.body.removeChild(a);
          this.$message.success("已导出，请在下载列表中查看");
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped>
.drawer {
  padding: 0 16px;
  color: #333;
}

.empty {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}
</style>
