// 异常类型分布
<template>
  <div>
    <h3 class="title">
      <i class="el-icon-data-analysis font-xl text-v"></i>
      <span class="text-v ml-1x">当前异常类型分布</span>
    </h3>
    <dv-border-box-12>
      <div ref="chart" class="chart-small"></div>
    </dv-border-box-12>
  </div>
</template>

<script>
// 引入 ECharts 主模块
var echarts = require("echarts/lib/echarts");
// 引入柱状图
require("echarts/lib/chart/bar");
// 引入提示框和标题组件
require("echarts/lib/component/tooltip");
require("echarts/lib/component/title");
import { biFeiFangWarn } from "@/api";
export default {
  name: "FlyingRoomBar",

  data() {
    return {
      chart: null,
      timer: null,
      brandId: "",
    };
  },

  created() {},

  mounted() {
    this.chart = echarts.init(this.$refs.chart, "dark");
  },

  beforeDestroy() {
    this.clearTimeout();
  },

  methods: {
    clearTimeout() {
      clearTimeout(this.timer);
      this.timer = null;
    },
    // 窗口变化
    resize() {
      this.chart.resize();
    },
    // 加载数据
    loadData(brandId) {
      this.clearTimeout();
      this.brandId = brandId;
      biFeiFangWarn({ brandId: this.brandId, t: new Date().getTime() })
        .then(({ data }) => {
          this.timer = setTimeout(() => {
            this.loadData(brandId);
          }, 10000);

          const { y_today } = data.data;
          const color = ["#12a5ff", "#91cc75", "#faa508"];
          this.chart.setOption({
            backgroundColor: "",
            tooltip: {
              trigger: "axis",
            },
            grid: {
              left: 60,
              right: 40,
              bottom: 40,
            },
            yAxis: {
              type: "value",
            },
            xAxis: {
              type: "category",
              data: ["超时退房", "退房未拔卡", "插卡超时(未入住)"],
            },
            series: [
              {
                type: "bar",
                barWidth: "30px",
                data: y_today.map((item, i) => {
                  return {
                    value: item,
                    itemStyle: {
                      color: color[i],
                    },
                  };
                }),
              },
            ],
          });
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped></style>
