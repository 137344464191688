var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _c("dv-border-box-12", [
        _c("div", { ref: "chart", staticClass: "chart-small" }),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", { staticClass: "title" }, [
      _c("i", { staticClass: "el-icon-data-line font-xl text-v" }),
      _c("span", { staticClass: "text-v ml-1x" }, [_vm._v("飞房监控趋势")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }