var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _c("div", { ref: "chartMap", staticClass: "chart" }),
      _c("dv-border-box-13", { staticClass: "relative" }, [
        _c("div", { staticClass: "table-small relative" }, [
          _c(
            "div",
            { staticStyle: { width: "100%", height: "100%" } },
            [
              _c(
                "el-row",
                { staticClass: "row header" },
                [
                  _c(
                    "el-col",
                    { staticClass: "flex-center", attrs: { span: 5 } },
                    [_vm._v("名称")]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "flex-center", attrs: { span: 4 } },
                    [_vm._v("今日")]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "flex-center bold", attrs: { span: 5 } },
                    [_vm._v("月累计")]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "flex-center", attrs: { span: 5 } },
                    [_vm._v("年累计")]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "flex-center", attrs: { span: 5 } },
                    [_vm._v("操作")]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "row" },
                [
                  _c(
                    "el-col",
                    { staticClass: "flex-center", attrs: { span: 5 } },
                    [_vm._v("≥2小时 疑似飞房")]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "flex-center", attrs: { span: 4 } },
                    [_vm._v(_vm._s(_vm.tableData.todayFfCnt || 0))]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "flex-center bold", attrs: { span: 5 } },
                    [_vm._v(_vm._s(_vm.tableData.monthFfCnt || 0))]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "flex-center", attrs: { span: 5 } },
                    [_vm._v(_vm._s(_vm.tableData.yearFfCnt || 0))]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "flex-center", attrs: { span: 5 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "", size: "mini" },
                          on: {
                            click: function ($event) {
                              _vm.totalVisible = true
                            },
                          },
                        },
                        [_vm._v("查看详情")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "row" },
                [
                  _c(
                    "el-col",
                    { staticClass: "flex-center", attrs: { span: 5 } },
                    [_vm._v("≥2小时 RCU离线")]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "flex-center", attrs: { span: 4 } },
                    [_vm._v(_vm._s(_vm.tableData.todayOffs || 0))]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "flex-center bold", attrs: { span: 5 } },
                    [_vm._v(_vm._s(_vm.tableData.monthOffs || 0))]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "flex-center", attrs: { span: 5 } },
                    [_vm._v(_vm._s(_vm.tableData.yearOffs || 0))]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "flex-center", attrs: { span: 5 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "", size: "mini" },
                          on: {
                            click: function ($event) {
                              _vm.rcuVisible = true
                            },
                          },
                        },
                        [_vm._v("查看详情")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("flying-room-map-total", {
        ref: "flyingRoomMapTotal",
        attrs: {
          visible: _vm.totalVisible,
          "hotel-list": _vm.hotelList,
          "brand-id": _vm.brandId,
        },
        on: {
          "update:visible": function ($event) {
            _vm.totalVisible = $event
          },
          detail: _vm.lookHotelDetail,
        },
      }),
      _c("flying-room-map-rcu", {
        ref: "flyingRoomMapRcu",
        attrs: {
          "brand-id": _vm.brandId,
          visible: _vm.rcuVisible,
          "hotel-list": _vm.hotelList,
        },
        on: {
          "update:visible": function ($event) {
            _vm.rcuVisible = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", { staticClass: "title" }, [
      _c("i", { staticClass: "el-icon-location-information font-xl text-v" }),
      _c("span", { staticClass: "text-v ml-1x" }, [_vm._v("飞房实时监控数据")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }