// 飞房统计查询
<template>
  <el-drawer
    title="飞房统计查询"
    :visible="visible"
    direction="rtl"
    size="85%"
    :modal-append-to-body="false"
    @close="close"
    @open="loadData"
  >
    <div class="drawer">
      <header class="flex-start">
        <p class="flex-shrink">地区：</p>
        <el-select
          class="mr-2x"
          v-model="search.area"
          placeholder="请选择"
          clearable
          filterable
          @clear="searchData"
          @change="areaChange"
        >
          <el-option label="东北区" value="东北区"> </el-option>
          <el-option label="华东区" value="华东区"> </el-option>
          <el-option label="华中区" value="华中区"> </el-option>
          <el-option label="华北区" value="华北区"> </el-option>
          <el-option label="华南区" value="华南区"> </el-option>
          <el-option label="西南区" value="西南区"> </el-option>
          <el-option label="西北区" value="西北区"> </el-option>
        </el-select>
        <p class="flex-shrink">城市：</p>
        <el-cascader
          class="mr-2x"
          ref="cascader"
          size="large"
          :options="citys"
          v-model="city"
          clearable
          filterable
          @change="cityChange"
        ></el-cascader>

        <p class="flex-shrink">酒店名称：</p>
        <el-select
          class="mr-2x"
          v-model="search.hotelId"
          placeholder="请选择"
          clearable
          filterable
          @clear="searchData"
          @change="searchData"
        >
          <el-option
            v-for="item in hotelList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>

        <el-button type="primary" icon="el-icon-search" @click="searchData"
          >查询
        </el-button>
        <el-button icon="el-icon-refresh-left" @click="resetSearch"
          >重置
        </el-button>
      </header>
      <el-table
        class="mt-2x"
        :height="tableHeight"
        :data="tableData"
        :header-cell-style="{
          background: 'rgba(250, 250, 250, 1)',
        }"
      >
        <el-table-column prop="date" label="序号" min-width="55">
          <template slot-scope="scope">{{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column
          prop="area"
          label="地区"
          min-width="100"
        ></el-table-column>
        <el-table-column
          prop="city"
          label="城市"
          min-width="100"
        ></el-table-column>
        <el-table-column prop="hname" label="酒店名称" min-width="120">
          <template slot-scope="{ row }">
            <el-button type="text" @click="lookHotelDetail(row)">{{
              row.hname
            }}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="ffCnt2H" min-width="110">
          <template slot="header">
            <p>2小时疑似</p>
            <p class="font-s">2小时≤时长＜4小时</p>
          </template>
        </el-table-column>

        <el-table-column prop="ffCnt4H" min-width="110">
          <template slot="header">
            <p>4小时疑似</p>
            <p class="font-s">4小时≤时长＜12小时</p>
          </template>
        </el-table-column>
        <el-table-column prop="ffCnt12H" label="12小时疑似" min-width="120">
          <template slot="header">
            <p>12小时疑似</p>
            <p class="font-s">12小时≤时长＜24小时</p>
          </template>
        </el-table-column>
        <el-table-column prop="ffCnt24H" label="24小时疑似" min-width="110">
          <template slot="header">
            <p>24小时疑似</p>
            <p class="font-s">时长≥24小时</p>
          </template>
        </el-table-column>
      </el-table>

      <!--分页-->
      <footer class="p-2x flex-between">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="search.pageSize"
          :total="total"
          :current-page="current"
          @size-change="sizeChange"
          @current-change="currentChange"
        >
        </el-pagination>

        <el-button v-if="tableData.length" type="primary" @click="exportExl"
          >导出</el-button
        >
      </footer>
    </div>
  </el-drawer>
</template>

<script>
import {
  biFeiFangStatistics,
  biFeiFangStatisticsExport,
} from "../../../../api";
import { provinceAndCityData } from "element-china-area-data";
import { returnNewArea } from "@/api/common.js";
export default {
  name: "FlyingRoomMapTotal",

  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    brandId: {
      type: [String, Array],
      default: "",
    },

    hotelList: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      city: "",
      search: {
        pageNum: 1,
        pageSize: 10,
        hotelId: "",
        area: "",
        city: "",
      },
      tableData: [],
      current: 1, // 当前页面
      total: 0, // 总数
      tableHeight: 0, // 表格高度
      timer: null,

      citys: provinceAndCityData,
    };
  },

  created() {
    this.resize();
  },

  methods: {
    // 导出表格
    exportExl() {
      biFeiFangStatisticsExport({
        brandId: this.brandId,
        hotelId: this.search.hotelId,
        area: this.search.area,
        city: this.search.city,
      })
        .then(({ data }) => {
          // 将二进制数据流转换为Blob对象
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          // 创建下载链接
          const url = window.URL.createObjectURL(blob);

          // 创建一个a标签，并模拟点击下载
          const a = document.createElement("a");
          a.href = url;
          a.download = `飞房统计查询.xlsx`;
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(a.href);
          document.body.removeChild(a);
          this.$message.success("已导出，请在下载列表中查看");
        })
        .catch(() => {});
    },
    resize() {
      let windowHeight = window.innerHeight;
      let fixedHeight = 195; // 页面固定占位高度
      this.tableHeight = windowHeight - fixedHeight;
    },

    close() {
      this.$emit("update:visible", false);
    },
    // 地区改变
    areaChange(val) {
      this.city = "";
      this.search.city = "";
      if (val) {
        this.citys = returnNewArea(val, provinceAndCityData);
      } else {
        this.citys = provinceAndCityData;
      }
      this.searchData();
    },

    // 选择城市改变
    cityChange() {
      const [node] = this.$refs.cascader.getCheckedNodes();
      if (node) {
        // 旧数据问题，得这样传参
        if (/市/.test(node.label)) {
          this.search.city = node.label;
        } else {
          this.search.city = parent.label;
        }
      } else {
        this.search.city = "";
      }

      this.searchData();
    },

    // 重置
    resetSearch() {
      this.search.pageNum = 1;
      this.search.pageSize = 10;
      this.search.hotelId = "";
      this.search.city = "";
      this.city = "";
      this.search.area = "";

      this.resetDate();
      this.searchData();
    },
    searchData() {
      this.search.pageNum = 1;
      this.loadData();
    },

    loadData() {
      biFeiFangStatistics({ ...this.search, brandId: this.brandId })
        .then(({ data }) => {
          this.tableData = data.data.rows;
          this.total = data.data.total;
        })
        .catch(() => {});
    },

    // 条数改变
    sizeChange(s) {
      this.search.pageNum = 1;
      this.search.pageSize = s;
      this.loadData();
    },
    // 页面改变
    currentChange(p) {
      this.search.pageNum = p;
      this.loadData();
    },

    // 查看单个酒店飞房详情
    lookHotelDetail(row) {
      this.$emit("detail", row);
    },
  },
};
</script>
<style scoped>
.font-s {
  font-size: 12px;
  font-weight: normal;
  color: #bbb;
}
</style>
