var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "title flex-between" },
        [
          _vm._m(0),
          _c(
            "el-button",
            {
              staticStyle: { opacity: "0" },
              attrs: { type: "primary", plain: "", size: "mini" },
            },
            [_vm._v("查看详情")]
          ),
        ],
        1
      ),
      _c("dv-border-box-12", { staticClass: "mt-1 relative" }, [
        _c("div", { ref: "chartBar", staticClass: "chart-small" }),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("i", { staticClass: "el-icon-pie-chart font-xl text-v" }),
      _c("span", { staticClass: "text-v ml-1x" }, [_vm._v("当前异常地区分布")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }