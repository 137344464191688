// 飞房控制效果监控
<template>
  <div>
    <h3 class="title">
      <i class="el-icon-data-line font-xl text-v"></i>
      <span class="text-v ml-1x">飞房监控趋势</span>
    </h3>
    <dv-border-box-12>
      <div ref="chart" class="chart-small"></div>
    </dv-border-box-12>
  </div>
</template>

<script>
// 引入 ECharts 主模块
var echarts = require("echarts/lib/echarts");
// 引入柱状图
require("echarts/lib/chart/line");
// 引入提示框和标题组件
require("echarts/lib/component/tooltip");
require("echarts/lib/component/title");

import { biFeiFangTendencyStatistics } from "@/api";
export default {
  name: "FlyingRoomLine",

  data() {
    return {
      chart: null, // 控制效果监控
      timer: null,
    };
  },

  created() {},

  mounted() {
    this.chart = echarts.init(this.$refs.chart, "dark");
  },

  beforeDestroy() {
    this.clearTimeout();
  },

  methods: {
    // 窗口变化
    resize() {
      this.chart.resize();
    },
    clearTimeout() {
      clearTimeout(this.timer);
      this.timer = null;
    },
    loadData(brandId) {
      this.clearTimeout();
      this.brandId = brandId;
      biFeiFangTendencyStatistics({ brandId, t: new Date().getTime() })
        .then(({ data }) => {
          const { date, pmsRate, ffrate } = data.data;
          this.setOption(date, pmsRate, ffrate);

          this.timer = setTimeout(() => {
            this.loadData(brandId);
          }, 10000);
        })
        .catch(() => {});
    },
    // 初始化图表
    setOption(date, pmsRate, ffrate) {
      this.chart.setOption({
        backgroundColor: "",
        legend: {
          top: 20,
          data: ["飞房率", "PMS出租率"],
        },
        grid: {
          left: 60,
          right: 40,
          bottom: 40,
        },
        tooltip: {
          trigger: "axis",
          formatter(params) {
            let result = '<div class="p-x">';
            params.forEach((item, index) => {
              result += `<div class="${index > 0 ? "mt-x" : ""}">${
                item.marker
              } ${item.seriesName}: ${item.value}%</div>`;
            });
            result += "</div>";
            return result;
          },
        },
        xAxis: {
          type: "category",
          data: date,
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: function (value) {
              return value + "%";
            },
          },
        },

        series: [
          {
            name: "飞房率",
            type: "line",
            symbol: "",
            symbolSize: 8,

            lineStyle: {
              width: 4,
            },
            data: ffrate,
          },
          {
            name: "PMS出租率",
            type: "line",
            symbolSize: 8,
            itemStyle: {
              color: "#91cc75",
            },
            lineStyle: {
              width: 4,
              color: "#91cc75",
            },
            data: pmsRate,
          },
        ],
      });
    },
  },
};
</script>
<style scoped>
.line-circle {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #000;
}
</style>
